import { env } from "../assets/env";

export default function HowToUse()
{
      return<>
          <h2 className="text-center mt-4 mb-5">How to use</h2>

          <div className="d-flex justify-content-center flex-column align-items-center mt-4 mb-5">
                  <h5 className="p-1 p-sm-0 font-weight-normal" style={{width: '100%', maxWidth: '560px'}}>
                        <span className="font-weight-bold">For students:</span> How to get help from tutors with any subject anytime from anywhere
                  </h5>
                  <iframe
                        src="https://www.youtube.com/embed/Co0_GEBRC2U?si=w3-9NHJBYhWRT1qH"
                        title="(For students) How to get help from tutors with any subject anytime from anywhere:"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen={true}
                        style={{width: '100%', maxWidth: '560px', border: 0}}
                        height={315+150}
                        className="rounded-lg"
                  />
            </div>

            <hr style={{width: '95%'}} />

            <div className="d-flex justify-content-center flex-column align-items-center mt-4 mb-5">
                  <h5 className="p-1 p-sm-0 font-weight-normal" style={{width: '100%', maxWidth: '560px'}}>
                        <span className="font-weight-bold">For teachers:</span> How to earn by teaching from home using MyXAcademy app
                  </h5>
                  <iframe
                        src="https://www.youtube.com/embed/YoJC6yZZoJI?si=iRYdZWdgzKNKOAS9"
                        title="(For teachers) How to earn by teaching from home using MyXAcademy app:"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen={true}
                        style={{width: '100%', maxWidth: '560px', border: 0}}
                        height={315+150}
                        className="rounded-lg"
                  />
            </div>

            <hr style={{width: '95%'}} />

            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
                  <h5 className="p-1 p-sm-0 d-flex flex-column text-center mb-3" style={{width: '100%', maxWidth: '560px'}}>
                        For any queries, questions, complains or suggestions, please send us direct message in MyXAcademy facebook page:
                  </h5>
                  <div className="btn btn-primary font-weight-bold mb-4 rounded-pill px-3" onClick={()=>window.open(env.facebookPageLink)}>
                        <i class="fa-brands fa-facebook" /> MyXAcademy Facebook Page
                  </div>
            </div>
      </>;
};
