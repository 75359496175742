//libraries
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";


export function UnlockLogin()
{
      const [searchParams, setSearchParams]=useSearchParams();
      const [msg, setMsg]=useState('<h1>Loading message. Please wait...</h1>');


      useEffect(()=>
      {
            (async function()
            {
                  let lc=searchParams.get("lc");

                  let res=await axios.post('/unlock-login', {lc});

                  setMsg(res.data.msg);
            })();
      },
      []);


      return<>
            <div className="text-center mt-5" dangerouslySetInnerHTML={{__html: msg}}></div>
      </>
};
