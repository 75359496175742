//libraries
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'animate.css';
import io from 'socket.io-client';

//components
import { Navbar } from './components/Navbar';

import { Landing } from './pages/Landing';
import { Home } from './pages/Home';
import { Messages } from './pages/Messages';
import { Profile } from './pages/Profile';
import { Coins } from './pages/Coins';
import { OfflinePg } from './pages/OfflinePg';

import { Inbox } from './pages/Inbox';
import { EditProfile } from './pages/EditProfile';
import { ManageOffers } from './pages/ManageOffers';
import { CreateNewOffer } from './pages/CreateNewOffer';
import { MsgPage } from './pages/MsgPage';
import { Details } from './pages/Details';
import { GoogleOauth } from './components/GoogleOauth';
import { BkashPayment } from './components/BkashPayment';
import { GoogleOauthWebviewHandle } from './components/GoogleOauthWebviewHandle';

import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { ResetPwd } from './pages/ResetPwd';
import { UnlockLogin } from './pages/UnlockLogin';

import { DloadApp } from './pages/DloadApp';

//css
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/all.css';

//js
import {refreshTokenClient} from './assets/sec/refreshTokenClient';
import { fontawesomeFree } from './assets/fontawesomeFree';
import { env } from './assets/env';
import { isMobileOrTablet } from './assets/isMobileOrTablet';
import { getAvgRating } from './assets/getAvgRating';
import HowToUse from './pages/HowToUse';

//config axios
axios.defaults.withCredentials=true;
axios.defaults.baseURL=env.backendBaseUrl;


export default function App()
{
	// ------------------------------------- useHooks -------------------------------------


	// ---------- APP COMPONENT ----------
	const [user, setUser]=useState(false);
	const [socket, setSocket]=useState(false);
	const [activeUserIdArr, setActiveUserIdArr]=useState(false);
	const [chatBoxes, setChatBoxes]=useState(false);
	const [newSocketMsg, setNewSocketMsg]=useState(false);
	const [profileImgHash, setProfileImgHash]=useState(Date.now());
	const [offerImgHash, setOfferImgHash]=useState(Date.now());
	const [dateNow, setDateNow]=useState(Date.now());
	const [fcmToken, setFcmToken]=useState(); //fcmToken values can be
	const [deviceType, setDeviceType]=useState(); //deviceType values can be 'computer'/'webview'/'mobile'
	const navigate=useNavigate();


	// ---------- HOME COMPONENT ----------
	const [isOffersLoaded, setIsOffersLoaded]=useState(false);
	const [offers, setOffers]=useState([]);
      const [classes, setClasses]=useState([]);
      const [departments, setDepartments]=useState([]);
      const [topics, setTopics]=useState([]);
	const [showTopicsAll, setShowTopicsAll]=useState(true);
      const [selectedClassId, setSelectedClassId]=useState(getCategData().selectedClassId);
      const [selectedDeptId, setSelectedDeptId]=useState(getCategData().selectedDeptId);
      const [selectedTopicId, setSelectedTopicId]=useState(getCategData().selectedTopicId);


	// ---------- INBOX COMPONENT ----------
	const [sentDeal, setSentDeal]=useState(false);
	const [receivedDealsArr, setReceivedDealsArr]=useState(false);
	const [socketNewReceivedDeal, setSocketNewReceivedDeal]=useState(false);
	const [acceptedSentDeal, setAcceptedSentDeal]=useState(false);
	const [acceptedReceivedDeal, setAcceptedReceivedDeal]=useState(false);
	const [newSocketMsg_selfInitiated, setNewSocketMsg_selfInitiated]=useState(false);
	const [receive_cancel_sentDeal, setReceive_cancel_sentDeal]=useState(false);


	// ------------------------------------- useEffects -------------------------------------


	// ---------- APP COMPONENT ----------
      useEffect(()=>
	{
		//RefreshToken initial call. But this will stop after running once.
		refreshTokenClient({setUser, willStop: true, setSocket});

		(async function()
		{
			let res=await axios.post('/getUser', {});
			setUser(res.data.user);
		})();
	},
	[]);


	useEffect(()=>
	{
		if(user===false || socket!==false) return;

		//user is logged in and socket is not connected then setting socket connection and calling refreshToken function to run recursively
		const newSocket=io.connect(env.backendBaseUrl, {withCredentials: true});
		setSocket(newSocket);
		refreshTokenClient({setUser, socket, setSocket});
	},
	[user.id_users]);


	useEffect(()=>
	{
		if(newSocketMsg===false) return;

		let data=newSocketMsg;

		let newChatObject=
		{
			fromId: data.fromId,
			toId: data.toId,
			body: data.body,
			dati: new Date(),
			isSeen: 0
		};

		// ---------- updating chatBoxes ----------
		let chatBoxesClone=JSON.parse(JSON.stringify(chatBoxes));
		for(let i=0; i<=chatBoxesClone.length-1; i++)
		{
			let chatBox=chatBoxesClone[i];
			if(chatBox.inboxUser.id_users===data.fromId)
			{
				chatBox.chats.push(newChatObject);
				return setChatBoxes(chatBoxesClone);
			};
		};


		(async function()
		{
			let res=await axios.post('/getUserFromId', {id_users: data.fromId});

			chatBoxesClone.push
			({
				inboxUser: res.data.user,
				chats: [newChatObject]
			});

			setChatBoxes(chatBoxesClone);
		})();
	},
	[newSocketMsg]);


	useEffect(()=>
	{
		if(newSocketMsg_selfInitiated===false) return;

		let data=newSocketMsg_selfInitiated;

		let newChatObject=
		{
			fromId: data.fromId,
			toId: data.toId,
			body: data.body,
			dati: new Date(),
			isSeen: 0
		};

		// ---------- updating chatBoxes ----------
		let chatBoxesClone=JSON.parse(JSON.stringify(chatBoxes));
		for(let i=0; i<=chatBoxesClone.length-1; i++)
		{
			let chatBox=chatBoxesClone[i];
			if(chatBox.inboxUser.id_users===data.toId)
			{
				chatBox.chats.push(newChatObject);
				return setChatBoxes(chatBoxesClone);
			};
		};
	},
	[newSocketMsg_selfInitiated]);


	useEffect(()=>
	{
		if(receive_cancel_sentDeal===false) return;

		let data=receive_cancel_sentDeal;

		let fromId=data.fromId;
		let receivedDealsArrClone=JSON.parse(JSON.stringify(receivedDealsArr));

		for(let i=0; i<=receivedDealsArrClone.length-1; i++)
		{
			let receivedDeal=receivedDealsArrClone[i];

			if(receivedDeal.fromId===fromId && receivedDeal.cancelledBy===0 && receivedDeal.isAccepted===0)
			{
				receivedDeal.cancelledBy=fromId;
				break;
			};
		};

		setReceivedDealsArr(receivedDealsArrClone);
	},
	[receive_cancel_sentDeal]);


	//sockets
	useEffect(()=>
	{
		if(socket===false) return;


		socket.on('receiveMessage', data=>
		{
			setNewSocketMsg(data);
		});


		socket.on('activeUserIdArr', data=>
		{
			setActiveUserIdArr(data);
		});


		socket.on('receive_deal', data=>
		{
			setSocketNewReceivedDeal(data);
		});


		socket.on('receive_cancel_sentDeal', data=>
		{
			setReceive_cancel_sentDeal(data);
		});


		socket.on('receive_cancel_receivedDeal', data=>
		{
			setSentDeal(false);
		});


		socket.on('receive_accept_deal', data=>
		{
			setSentDeal(false);


			// ----- showing accepted sent deal -----
			let username=data.username;
			let payment=data.payment;

			setAcceptedSentDeal(`You are helping ${username} for ${payment} coins`);
		});


		socket.on('receive_finish_receivedDeal', async (data)=>
		{
			setAcceptedSentDeal(false);


			//increasing coins
			let res=await axios.post('/getUser', {});
			setUser(res.data.user);
		});


		socket.on('receive_finish_SentDeal', data=>
		{
			alert('They finished the deal. Please give a review.');

			let fromId=data.fromId;

			setAcceptedReceivedDeal(false);

			navigate(`/details?ui=${fromId}`);
		});


		socket.on('receiveMessage_selfInitiated', data=>
		{
			setNewSocketMsg_selfInitiated(data);
		});


		return ()=>
		{
			socket.off('receiveMessage');
			socket.off('activeUserIdArr');
			socket.off('receive_deal');
			socket.off('receive_cancel_sentDeal');
			socket.off('receive_cancel_receivedDeal');
			socket.off('receive_accept_deal');
			socket.off('receive_finish_receivedDeal');
			socket.off('receive_finish_SentDeal');
			socket.off('receiveMessage_selfInitiated');
		};
	},
	[socket]);


	useEffect(()=>
	{
		if(user===false) return setChatBoxes(false);

		(async function()
		{
			let res=await axios.post('/getChatBoxes', {});
			setChatBoxes(res.data.chatBoxes);
		})();
	},
	[user.id_users]);


	// ---------- HOME COMPONENT ----------
	useEffect(()=>
	{
		(async function()
		{
			const res=await axios.post('/getAllClasses', {});
			if(res.data.classes===false) setClasses([]);
			setClasses(res.data.classes);
		})();
	},
	[]);


      useEffect(()=>
	{
		let categData={selectedClassId, selectedDeptId, selectedTopicId};
		categData=JSON.stringify(categData);
		localStorage.setItem('categData', categData);

		updateOffers();
	},
	[selectedClassId, selectedDeptId, selectedTopicId]);


	useEffect(()=>
	{
		let offersClone=JSON.parse(JSON.stringify(offers));

		offersClone.forEach(offer => offer.showTopics=showTopicsAll);

		setOffers(offersClone);
	},
	[showTopicsAll]);


	// ---------- INBOX & NAVBAR COMPONENT ----------
	useEffect(()=>
	{
		if(user===false)
		{
			setReceivedDealsArr(false);
			setSentDeal(false);
			setAcceptedSentDeal(false);
			setAcceptedReceivedDeal(false);
			return;
		};

		(async function()
		{
			let res=await axios.post('/getMyActiveReceivedDeals');
			setReceivedDealsArr(res.data.receivedDealsArr);
		})();

		(async function()
		{
			let res=await axios.post('/getMyActiveSentDeal');
			setSentDeal(res.data.sentDeal);
		})();

		(async function()
		{
			let res=await axios.post('/getAcceptedSentDeal');
			setAcceptedSentDeal(res.data.acceptedSentDeal);
		})();

		(async function()
		{
			let res=await axios.post('/getAcceptedReceivedDeal');
			setAcceptedReceivedDeal(res.data.acceptedReceivedDeal);
		})();
	},
	[user.id_users]);


	useEffect(()=>
	{
		if(socketNewReceivedDeal===false) return;

		let data=socketNewReceivedDeal;

		let newReceivedDealObj=
		{
			fromId: data.fromId,
			toId: data.toId,
                  payment: data.payment,
			cancelledBy: data.cancelledBy,
			isAccepted: data.isAccepted,
			finishedBy: data.finishedBy,
			dati: data.dati
		};

		let receivedDealsArrClone=[];
		if(receivedDealsArr!==false) receivedDealsArrClone=JSON.parse(JSON.stringify(receivedDealsArr));
		receivedDealsArrClone.push(newReceivedDealObj);
		setReceivedDealsArr(receivedDealsArrClone);
	},
	[socketNewReceivedDeal]);


	// ---------- COMMUNICATING WITH REACT_NATIVE - START ----------

	//listener to receive msgs from ReactNative
	useEffect(()=>
	{
		//this will work only if device is mobile or tab
		if(isMobileOrTablet()===false) return setDeviceType('computer');

		try
		{
			setDeviceType('webview');


			//sending data to ReactNative
			let data={status: 'push-token'};
			window.ReactNativeWebView.postMessage(JSON.stringify(data));

			function receiveFromReactNative(nativeEvent)
			{
				let data=JSON.parse(nativeEvent.data);

				if(data.status==='push-token')
				{
					setFcmToken(data.fcmToken);
				};

				if(data.status==='google-oauth-url')
				{
					window.location.href=data.googleOauthUrl;
				};
			};

			document.addEventListener('message', receiveFromReactNative);
			return ()=> document.removeEventListener('message', receiveFromReactNative);
		}

		catch(error)
		{
			setDeviceType('mobile');
		};
	},
	[]);

	// ---------- COMMUNICATING WITH REACT_NATIVE - END ----------


	//saving fcm token, if some conditions meet
	useEffect(()=>
	{
		if(user===false || deviceType!='webview') return;

		if(user.fcm_token!=fcmToken) axios.post('/saveFcmToken', {fcm_token: fcmToken});
	},
	[user.id_users]);


	// ------------------------------------- functions -------------------------------------


	function getCategData()
	{
		let categData=localStorage.getItem('categData');
		if(categData===null) return {selectedClassId: 0, selectedDeptId: 0, selectedTopicId: 0};
		return JSON.parse(categData);
	};


	async function updateOffers()
	{
		setIsOffersLoaded(false);

		const res=await axios.post('/getOffers', {selectedClassId, selectedDeptId, selectedTopicId});
		let offers=res.data.offers;

		if(offers===false) return setOffers([]);

		offers.forEach(offer => offer.showTopics=showTopicsAll);

		//Adding avgRating
		offers.forEach(offer=>
		{
			let avgRating=getAvgRating(offer.reviewsArr);
			offer.avgRating=avgRating;
		});

		//sorting
		offers.sort((a, b)=>
		{
			return b.avgRating*b.reviewsArr.length - a.avgRating*a.reviewsArr.length;
		});

		setOffers(offers);

		setIsOffersLoaded(true);
	};


	function renderActiveStatus(id_users)
      {
            function statusList(status)
            {
                  if(status==='loading') return(
                        <div className="text-dark">
                              <i className="fa-duotone fa-spinner-third fa-spin"></i>
                        </div>
                  );

                  if(status==='active') return(
                        <div style={{color: '#00ff00'}}>
                              <i className="fa-solid fa-circle-small"></i>
                        </div>
                  );
            };


            if(user===false) return statusList('active');

            if(activeUserIdArr===false) return statusList('loading');

            if(activeUserIdArr.includes(id_users)) return statusList('active');
      };


	function renderNavbar(selectedNavItem)
	{
		return <Navbar selectedNavItem={selectedNavItem} user={user} setUser={setUser} socket={socket} chatBoxes={chatBoxes} sentDeal={sentDeal} setSentDeal={setSentDeal} acceptedSentDeal={acceptedSentDeal} setAcceptedSentDeal={setAcceptedSentDeal} acceptedReceivedDeal={acceptedReceivedDeal} setAcceptedReceivedDeal={setAcceptedReceivedDeal} />;
	};


	// ------------------------------------- rendering -------------------------------------


	//location blocking if GMT!=6
	let GMT=-new Date().getTimezoneOffset()/60;
	if(GMT != 6) return<>
		<div className='text-center'>
			<h1 className='mt-5 mb-4'><u>Content not available in your country</u></h1>
			<h4>If you think that this is a mistake, then let us know.</h4>
			<h4>Write your name, location & issue and send an email to</h4>
			<h4 className='font-weight-bold'>MyXAcademy.official@gmail.com</h4>
		</div>
	</>;


	if(navigator.onLine===false) return <OfflinePg />


	if(! deviceType) return;


	if(deviceType==='mobile') return<>
		<Routes>
			<Route path='/google-oauth' element={
				<GoogleOauthWebviewHandle />
			} />

			<Route path='/' element={
				<Landing deviceType={deviceType} />
			} />

			<Route path='/*' element={
				<DloadApp />
			} />
		</Routes>
	</>;


	return<>
		{fontawesomeFree()}

		<Routes>

			<Route path='/' element={
				<Landing deviceType={deviceType} />
			} />

			<Route path='/home' element={<>
				{renderNavbar('home')}
				<Home user={user} activeUserIdArr={activeUserIdArr} offers={offers} setOffers={setOffers} departments={departments} setDepartments={setDepartments} topics={topics} setTopics={setTopics} selectedDeptId={selectedDeptId} setSelectedDeptId={setSelectedDeptId} selectedTopicId={selectedTopicId} setSelectedTopicId={setSelectedTopicId} chatBoxes={chatBoxes} setChatBoxes={setChatBoxes} showTopicsAll={showTopicsAll} setShowTopicsAll={setShowTopicsAll} profileImgHash={profileImgHash} offerImgHash={offerImgHash} renderActiveStatus={renderActiveStatus} dateNow={dateNow} classes={classes} selectedClassId={selectedClassId} setSelectedClassId={setSelectedClassId} isOffersLoaded={isOffersLoaded} />
			</>} />

			<Route path='/messages' element={<>
				{renderNavbar('messages')}
				<Messages user={user} chatBoxes={chatBoxes} socket={socket} renderActiveStatus={renderActiveStatus} dateNow={dateNow} />
			</>} />

			<Route path='/inbox' element={
				<Inbox user={user} setUser={setUser} socket={socket} chatBoxes={chatBoxes} setChatBoxes={setChatBoxes} renderNavbar={renderNavbar} newSocketMsg={newSocketMsg} profileImgHash={profileImgHash} renderActiveStatus={renderActiveStatus} dateNow={dateNow} sentDeal={sentDeal} setSentDeal={setSentDeal} receivedDealsArr={receivedDealsArr} setReceivedDealsArr={setReceivedDealsArr} setAcceptedReceivedDeal={setAcceptedReceivedDeal} newSocketMsg_selfInitiated={newSocketMsg_selfInitiated} />
			} />

			<Route path='/profile' element={<>
				{renderNavbar('profile')}
				<Profile user={user} setUser={setUser} socket={socket} setSocket={setSocket} profileImgHash={profileImgHash} deviceType={deviceType} />
			</>} />

			<Route path='/coins' element={<>
				{renderNavbar('coins')}
				<Coins user={user} setUser={setUser} />
			</>} />

			<Route path='/editProfile' element={<>
				{renderNavbar('profile')}
				<EditProfile user={user} setUser={setUser} profileImgHash={profileImgHash} setProfileImgHash={setProfileImgHash} updateOffers={updateOffers} />
			</>} />

			<Route path='/manageOffers' element={<>
				{renderNavbar('profile')}

				{(user.teacherMode==='on') ? <ManageOffers user={user} activeUserIdArr={activeUserIdArr} chatBoxes={chatBoxes} setChatBoxes={setChatBoxes} updateOffers={updateOffers} profileImgHash={profileImgHash} offerImgHash={offerImgHash} setOfferImgHash={setOfferImgHash} renderActiveStatus={renderActiveStatus} dateNow={dateNow} />
				: <MsgPage msg='Please turn on teacherMode to view this page' />}
			</>} />

			<Route path='/createNewOffer' element={<>
				{renderNavbar('profile')}

				{(user.teacherMode==='on') ? <CreateNewOffer updateOffers={updateOffers} classes={classes} />
				: <MsgPage msg='Please turn on teacherMode to view this page' />}
			</>} />

			<Route path='/details' element={<>
				{renderNavbar('')}
				<Details user={user} profileImgHash={profileImgHash} dateNow={dateNow} />
			</>} />

			<Route path='/login' element={
				(user===false) ? <Login setUser={setUser} deviceType={deviceType} />
				: <Navigate to='/home' />
			} />

			<Route path='/register' element={
				(user===false) ? <Register setUser={setUser} />
				: <Navigate to='/home' />
			} />

			<Route path='/resetPwd' element={
				(user===false) ? <ResetPwd setUser={setUser} />
				: <Navigate to='/home' />
			} />

			<Route path='/unlock-login' element={<>
				{renderNavbar('')}
				<UnlockLogin />
			</>} />

			<Route path='/google-oauth' element={
				<GoogleOauth setUser={setUser} />
			} />

			<Route path='/bkashPayment' element={<>
				{renderNavbar('coins')}
				<BkashPayment setUser={setUser} />
			</>} />

			<Route path='/how-to-use' element={<>
				{renderNavbar('profile')}
				<HowToUse />
			</>} />

			<Route path='/*' element={<>
				{renderNavbar('')}
				<MsgPage msg='Page Not Found' />
			</>} />

		</Routes>
	</>;
};
