//libraries
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";

//components
import { EditOffer } from "./EditOffer";
import { OfferCard } from "../components/OfferCard";

//css
import '../styles/manageOffers.css';
import { OfferTopics } from "../components/OfferTopics";

//js
import { getAvgRating } from "../assets/getAvgRating";


export function ManageOffers({user, activeUserIdArr, chatBoxes, setChatBoxes, updateOffers, profileImgHash, offerImgHash, setOfferImgHash, renderActiveStatus, dateNow})
{
      const [offers, setOffers]=useState([]);
      const [isEditOfferPgVisible, setIsEditOfferPgVisible]=useState(false);
      const [editOfferId, setEditOfferId]=useState(0);


      useEffect(()=>
      {
            updateMyOffers();
      },
      []);

      async function updateMyOffers()
      {
            let res=await axios.post('/getMyOffers', {});
            let offers=res.data.offers;

            //Adding avgRating
            offers.forEach(offer=>
            {
                  let avgRating=getAvgRating(offer.reviewsArr);
                  offer.avgRating=avgRating;
            });

            setOffers(offers);
      };

      function showEditOfferPg(id_offers)
      {
            setIsEditOfferPgVisible(true);
            setEditOfferId(id_offers);
      };

      function hideEditOfferPg()
      {
            setIsEditOfferPgVisible(false);
            setEditOfferId(0);
      };


      if(isEditOfferPgVisible)
      return<>
            <EditOffer offer={ offers.filter(offer=>offer.id_offers===editOfferId)[0] } updateMyOffers={updateMyOffers} hideEditOfferPg={hideEditOfferPg} updateOffers={updateOffers} offerImgHash={offerImgHash} setOfferImgHash={setOfferImgHash} />
      </>

      return<>
            <div className="container-fluid mb-5">

                  <h2 className="text-center mt-5 mb-sm-0 mb-5">My Offers</h2>

                  <Link to='/createNewOffer' className="btn btn-success addNewOfferBtn rounded-pill">
                        <i className="fa-solid fa-plus-large"></i>
                        <span> Add new offer</span>
                  </Link>

                  <div className="row d-flex justify-content-around">
                        {offers.map(offer=><>
                              <div key={offer.id_offers} className="card-home-box d-flex justify-content-center mb-5      col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                    <div className="cardAndDetailsBox">
                                          <OfferCard user={user} offer={offer} activeUserIdArr={activeUserIdArr} chatBoxes={chatBoxes} setChatBoxes={setChatBoxes} profileImgHash={profileImgHash} offerImgHash={offerImgHash} renderActiveStatus={renderActiveStatus} dateNow={dateNow} />
                                          <OfferTopics offer={offer} showEditOfferPg={showEditOfferPg} />
                                          <div className="mt-3">
                                                <div onClick={()=>showEditOfferPg(offer.id_offers)} className="c-pointer text-secondary d-inline px-2 py-1">Edit</div>
                                          </div>
                                    </div>
                              </div>
                        </>)}
                  </div>

            </div>
      </>
};
