//libraries
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import axios from 'axios';

//components
import { MsgBox } from '../components/MsgBox';

//css
import '../styles/createNewOffer.css';

//js
import { env } from '../assets/env';


export function EditOffer({offer, updateMyOffers, hideEditOfferPg, updateOffers, offerImgHash, setOfferImgHash})
{
      const [msgBoxMsg, setMsgBoxMsg]=useState('');
      const [isMsgBoxVisible, setIsMsgBoxVisible]=useState(false);

      const [description, setDescription]=useState('');
      const [selectedId_topicsArr, setSelectedId_topicsArr]=useState([]);
      const [imageFile, setImageFile]=useState(false);

      const [topics, setTopics]=useState([]);

      const [isNewOfferPosted, setIsNewOfferPosted]=useState(false);
      const [isOfferDeleted, setIsOfferDeleted]=useState(false);


      useEffect(()=>
      {
            //setting already chosen inputes of this offer
            setDescription(offer.offer_description);

            updateTopicsList(offer.department.id_departments);

            let chosenTopicsIdArr=[];
            offer.topics.forEach(function(topic){chosenTopicsIdArr.push(topic.id_topics)});
            setSelectedId_topicsArr(chosenTopicsIdArr);
      },
      []);


      useEffect(()=>
      {
            if(imageFile===false) return;

            let confirmImageUpload=window.confirm(`Do you want to set this image as offer image? Filename: ${imageFile.name}`);
            if(confirmImageUpload===false) return setImageFile(false);

            const formData=new FormData();
            formData.append('file', imageFile);

            (async function()
            {
                  let res=await axios.post(`/uploadOfferImage?id_offers=${offer.id_offers}`, formData);

                  if(res.data.isUploaded===false) alert(res.data.msg);
                  else alert('Offer image updated');

                  //don't use success condition here to setImagHash because uploading image file bigger than allowed limit will delete the image by multer
                  //so image hash must be updated even if 'isUploaded' is failed
                  setOfferImgHash(Date.now());
            })();

            setImageFile(false);
      },
      [imageFile]);


      function showMsg(msg)
      {
            setMsgBoxMsg(msg);
            setIsMsgBoxVisible(true);
      };


      async function updateTopicsList(id_departments)
      {
            //selected topics array MUST be emptied!
            setSelectedId_topicsArr([]);

            //updating topics list in frontend
            const res=await axios.post('/updateTopicsList', {id_departments});
            let topics=res.data.topics;
            if(topics===false) return setTopics([]);
            setTopics(topics);
      };


      function handleTopicCheckbox(id_topics, isChecked)
      {
            let selectedId_topicsArrClone=[...selectedId_topicsArr];

            if(isChecked)
            {
                  selectedId_topicsArrClone.push(id_topics);
            }

            else
            {
                  let index=selectedId_topicsArrClone.indexOf(id_topics);
                  selectedId_topicsArrClone.splice(index, 1);
            };

            setSelectedId_topicsArr(selectedId_topicsArrClone);
      };


      async function saveNewOfferDescription()
      {
            let id_offers=offer.id_offers;

            let res=await axios.post('/saveNewOfferDescription',
            {
                  id_offers: id_offers,
                  description: description
            });

            if(res.data.isSaved===true)
            {
                  setIsNewOfferPosted(true);
                  showMsg('Offer description saved');

                  updateOffers();
            };
      };


      async function saveNewOfferTopicsList()
      {
            let id_offers=offer.id_offers;

            let res=await axios.post('/saveNewOfferTopicsList',
            {
                  id_offers: id_offers,
                  id_topicsArr: selectedId_topicsArr
            });

            if(res.data.isSaved===false)
            {
                  setIsNewOfferPosted(false);
                  showMsg(res.data.msg);
            };

            if(res.data.isSaved===true)
            {
                  setIsNewOfferPosted(true);
                  showMsg('Offer topics saved');

                  updateOffers();
            };
      };


      async function removeOfferImage()
      {
            let id_offers=offer.id_offers;

            let res=await axios.post('/removeOfferImage', {id_offers});

            if(res.data.isRemoved===false) alert(res.data.msg);
            else alert('Offer image removed');

            setOfferImgHash(Date.now());
      };


      async function deleteMyOffer()
      {
            let confirmation=window.confirm('Do you want to delete this offer?');
            if(confirmation===false) return;

            let id_offers=offer.id_offers;
            let res=await axios.post('/deleteMyOffer', {id_offers});

            if(res.data.isDeleted===false)
            {
                  setIsOfferDeleted(false);
                  showMsg(res.data.msg);
            };

            if(res.data.isDeleted===true)
            {
                  setIsOfferDeleted(true);
                  showMsg('Offer has been deleted');

                  updateOffers();
            };
      };


      function handleSelectAll()
      {
            const allTopicsIdArr=topics.map(x => x.id_topics);
            setSelectedId_topicsArr(allTopicsIdArr);
      };


      function handleUnselectAll()
      {
            setSelectedId_topicsArr([]);
      };


      return<>
            { isMsgBoxVisible && <MsgBox setIsMsgBoxVisible={setIsMsgBoxVisible} msgBoxMsg={msgBoxMsg} handleDone={ (isNewOfferPosted || isOfferDeleted) ? function(){ hideEditOfferPg(); updateMyOffers(); } : function(){} } /> }


            <div className="container-fluid p-0" style={ (isMsgBoxVisible) ? {opacity: '0.1', pointerEvents: 'none'} : {} }>

                  <div style={{marginBottom: '50px'}}>
                        <div>
                              <h2 className="text-center mt-4 mt-sm-5 mb-4">Edit Offer</h2>

                              <div className="d-flex justify-content-center mr-2 mb-2">
                                    <div className="d-flex justify-content-end" style={{width: '500px'}}>
                                          <button onClick={hideEditOfferPg} className="btn btn-secondary py-1 px-2"><FontAwesomeIcon icon={faX} /></button>
                                    </div>
                              </div>

                              <div className="d-flex justify-content-center">
                                    <div className="table" style={{maxWidth: '500px'}}>

                                          <div className='px-3 pb-2 pt-3 mb-4 rounded bg-white'>
                                                <div className="mb-2">Offer description:</div>
                                                <div className="input-group">
                                                      <div className="input-group-prepend">
                                                            <span className="input-group-text bg-white pr-0 d-flex flex-column justify-content-start">I will</span>
                                                      </div>
                                                      <textarea value={description} onChange={e=>setDescription(e.target.value)} className="form-control px-1 border-left-0" rows="2" style={{resize: 'none'}}></textarea>
                                                </div>
                                                <div className='d-flex justify-content-end mt-2'>
                                                      <button className='rounded-pill btn btn-success py-0 px-2' onClick={saveNewOfferDescription}>Save description</button>
                                                </div>
                                          </div>

                                          <div className="pl-3 pt-3 rounded bg-white">Class: {offer.class_.className}</div>
                                          <div className="pl-3 rounded bg-white">Subject: {offer.department.deptName}</div>

                                          <div className="mb-4 rounded bg-white p-3">
                                                <div>Topics:</div>
                                                <div className='mb-2 text-danger' style={{fontSize: 'small'}}>Please choose only the topics you are expert in</div>

                                                <ul className="list-group mb-2">
                                                      {topics.map(topic=><>
                                                            <li key={topic.id_topics} className="list-group-item d-flex align-items-center pl-0 border-0">
                                                                  <input id={topic.id_topics} checked={selectedId_topicsArr.includes(topic.id_topics)} onChange={e=>handleTopicCheckbox(topic.id_topics, e.target.checked)} className='topicCheckbox' type="checkbox" />
                                                                  <span className='ml-2'>
                                                                        <label htmlFor={topic.id_topics} className='my-0'>{topic.topicName}</label>
                                                                  </span>
                                                            </li>
                                                      </>)}
                                                </ul>

                                                <div className='mb-4'>
                                                      <div className='btn btn-outline-primary py-0 px-3 mr-2' onClick={handleSelectAll}>select all</div>
                                                      <div className='btn btn-outline-warning py-0' onClick={handleUnselectAll}>unselect all</div>
                                                </div>

                                                <div className='d-flex justify-content-end'>
                                                      <button className='rounded-pill btn btn-success py-0 px-2' onClick={saveNewOfferTopicsList}>Save selected topics</button>
                                                </div>
                                          </div>

                                          <div className='mb-4 bg-white'>
                                                <div className="p-3 mr-2 mb-2">Offer image:</div>
                                                <div className='cardImgBox text-center mb-5'>
                                                      <img className='rounded w-100 h-100' src={`${env.backendBaseUrl}/offerImages/${offer.id_offers}.jpg?${offerImgHash}`} onError={e=>e.target.src=require('../assets/defaultImage.jpg')} style={{maxWidth: '50%', minWidth: '50%', objectFit: 'cover'}} />
                                                </div>
                                                <div className='d-flex justify-content-around pb-4'>
                                                      <button className='rounded-pill btn btn-danger py-0 px-2' onClick={removeOfferImage}>Remove image</button>
                                                      <button className='rounded-pill btn btn-success py-0 px-2' onClick={()=>document.getElementById('inputOfferImageId').click()}>Upload new image</button>
                                                      <input hidden id="inputOfferImageId" onChange={(e)=>setImageFile(e.target.files[0])} type='file' />
                                                </div>
                                          </div>

                                          <div className="d-flex justify-content-end mb-3">
                                                <button onClick={hideEditOfferPg} className="rounded-pill btn btn-secondary mr-1">Close</button>
                                          </div>

                                          <div className="d-flex justify-content-start">
                                                <div onClick={deleteMyOffer} className='text-danger font-weight-bold c-pointer ml-1'>Delete offer</div>
                                          </div>

                                    </div>
                              </div>

                        </div>
                  </div>

            </div>
      </>
};
