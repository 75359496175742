//libraries
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faX} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

//components
import { MsgBox } from '../components/MsgBox';

//css
import '../styles/createNewOffer.css';


export function CreateNewOffer({updateOffers, classes})
{
      const [msgBoxMsg, setMsgBoxMsg]=useState('');
      const [isMsgBoxVisible, setIsMsgBoxVisible]=useState(false);

      const [selectedClassId, setSelectedClassId]=useState(0);
      const [selectedDeptId, setSelectedDeptId]=useState(0);

      const [description, setDescription]=useState('');
      const [selectedId_topicsArr, setSelectedId_topicsArr]=useState([]);
      const [imageFile, setImageFile]=useState(false);

      const [departments, setDepartments]=useState([]);
      const [topics, setTopics]=useState([]);

      const [isNewOfferPosted, setIsNewOfferPosted]=useState(false);

      const navigate=useNavigate();


      useEffect(()=>
      {
            setDepartments([]);
            setSelectedDeptId(0);

            if(selectedClassId==0) return;

            (async function()
            {
                  let res=await axios.post('/getNotOfferedDepartments', {selectedClassId});
                  let departments=res.data.departments;
                  if(departments===false) return setDepartments([]);
                  setDepartments(departments);
            })();
      },
      [selectedClassId]);


      useEffect(()=>
      {
            //selected topics array MUST be emptied!
            setTopics([])
            setSelectedId_topicsArr([]);

            if(selectedDeptId==0) return;

            (async function()
            {
                  //updating topics list in frontend
                  const res=await axios.post('/updateTopicsList', {id_departments: selectedDeptId});
                  let topics=res.data.topics;
                  if(topics===false) return setTopics([]);
                  setTopics(topics);
            })();
      },
      [selectedDeptId]);


      function showMsg(msg)
      {
            setMsgBoxMsg(msg);
            setIsMsgBoxVisible(true);
      };


      function handleSelectTopicCheckbox(id_topics, isChecked)
      {
            let selectedId_topicsArrClone=[...selectedId_topicsArr];

            if(isChecked)
            {
                  selectedId_topicsArrClone.push(id_topics);
            }

            else
            {
                  let index=selectedId_topicsArrClone.indexOf(id_topics);
                  selectedId_topicsArrClone.splice(index, 1);
            };

            setSelectedId_topicsArr(selectedId_topicsArrClone);
      };


      async function handleSaveOffer()
      {
            let res=await axios.post('/saveNewOffer',
            {
                  description: description,
                  id_topicsArr: selectedId_topicsArr,
                  imageFile: imageFile
            });

            if(res.data.isPosted===false)
            {
                  setIsNewOfferPosted(false);
                  showMsg(res.data.msg);
            };

            if(res.data.isPosted===true)
            {
                  updateOffers();

                  setIsNewOfferPosted(true);
                  showMsg(res.data.msg);


                  //checking if image also needs to be uploaded
                  if(res.data.autoRequest_uploadOfferImage===true)
                  {
                        let id_offers=res.data.id_offers;

                        const formData=new FormData();
                        formData.append('file', imageFile);


                        let res2=await axios.post(`/uploadOfferImage?id_offers=${id_offers}`, formData);

                        if(res2.data.isUploaded===false) alert(res2.data.msg);
                        else alert('Offer image uploaded successfully');
                  };
            };
      };


      function handleSelectAll()
      {
            const allTopicsIdArr=topics.map(x => x.id_topics);
            setSelectedId_topicsArr(allTopicsIdArr);
      };


      function handleUnselectAll()
      {
            setSelectedId_topicsArr([]);
      };


      return<>
            { isMsgBoxVisible && <MsgBox setIsMsgBoxVisible={setIsMsgBoxVisible} msgBoxMsg={msgBoxMsg} handleDone={ (isNewOfferPosted) ? ()=>navigate('/manageOffers') : ()=>{} } /> }

            <div className="container-fluid p-0" style={ (isMsgBoxVisible) ? {opacity: '0.1', pointerEvents: 'none'} : {} }>

                  <div style={{marginBottom: '80px'}}>
                        <div>
                              <h2 className="text-center mt-4 mt-sm-5 mb-4">Create New Offer</h2>


                              <div className="d-flex justify-content-center mr-2 mb-2">
                                    <div className="d-flex justify-content-end" style={{width: '500px'}}>
                                          <Link to='/manageOffers' replace={true} className="btn btn-secondary py-1 px-2"><FontAwesomeIcon icon={faX} /></Link>
                                    </div>
                              </div>


                              <div className="d-flex justify-content-center">
                                    <div className="table p-3 rounded bg-white" style={{maxWidth: '500px'}}>

                                          <div className="mb-1">Short description:</div>
                                          <div className="input-group mb-4 border rounded">
                                                <div className="input-group-prepend">
                                                      <span className="input-group-text bg-white pr-0 d-flex flex-column justify-content-start border-0">I will</span>
                                                </div>
                                                <textarea onChange={(e)=>setDescription(e.target.value)} className="form-control px-1 border-0" rows="2" style={{resize: 'none'}}></textarea>
                                          </div>

                                          <div className="mb-3">
                                                <select onChange={(e)=>setSelectedClassId(e.target.value)} className="custom-select text-center" autoComplete='off'>
                                                      <option value={0}>--- Select Class ---</option>
                                                      {classes.map((class_, i)=>
                                                            <option key={class_.id_classes} value={class_.id_classes}>{class_.className}</option>
                                                      )}
                                                </select>
                                          </div>

                                          { (selectedClassId!=0) &&
                                                <div className="mb-4">
                                                      <select onChange={e=>setSelectedDeptId(e.target.value)} className="custom-select text-center" autoComplete='off'>
                                                            <option value={0}>--- Select Subject ---</option>
                                                            {departments.map((dept, i)=>
                                                                  <option key={dept.id_departments} value={dept.id_departments}>{dept.deptName}</option>
                                                            )}
                                                      </select>
                                                </div>
                                          }

                                          { (selectedDeptId!=0) && <>
                                                <div className="mt-5 mb-4">
                                                      <div>Topics:</div>
                                                      <div className='mb-2 text-danger' style={{fontSize: 'small'}}>Please choose only the topics you are expert in</div>

                                                      <ul className="list-group">
                                                            {topics.map(topic=><>
                                                                  <li key={topic.id_topics} className="list-group-item d-flex align-items-center pl-0 border-0">
                                                                        <input checked={selectedId_topicsArr.includes(topic.id_topics)} id={topic.id_topics} onChange={e=>handleSelectTopicCheckbox(topic.id_topics, e.target.checked)} className='topicCheckbox' type="checkbox" />
                                                                        <label htmlFor={topic.id_topics} className='ml-2'>{topic.topicName}</label>
                                                                  </li>
                                                            </>)}
                                                      </ul>
                                                </div>

                                                <div className='btn btn-outline-primary py-0 px-3 mr-2' onClick={handleSelectAll}>select all</div>
                                                <div className='btn btn-outline-warning py-0' onClick={handleUnselectAll}>unselect all</div>
                                          </>}


                                          <div className='mt-5 mb-4 bg-white'>
                                                <div className="mb-2">Offer image:</div>
                                                <div className='cardImgBox text-center mb-5'>
                                                      <img className='rounded w-100 h-100' src={ (imageFile===false) ? '' : URL.createObjectURL(imageFile) } onError={e=>e.target.src=require('../assets/defaultImage.jpg')} style={{maxWidth: '50%', minWidth: '50%', objectFit: 'cover'}} />
                                                </div>
                                                <div className='d-flex justify-content-around pb-4'>
                                                      <button className='rounded-pill btn btn-danger py-0 px-2' onClick={()=>setImageFile(false)}>Remove image</button>
                                                      <button className='rounded-pill btn btn-success py-0 px-2' onClick={()=>document.getElementById('inputOfferImageId').click()}>Upload new image</button>
                                                      <input hidden id="inputOfferImageId" onChange={(e)=>setImageFile(e.target.files[0])} type='file' />
                                                </div>
                                          </div>

                                          <div className="d-flex justify-content-end">
                                                <button onClick={handleSaveOffer} className="btn btn-success px-4 rounded-pill">Save</button>
                                          </div>

                                    </div>
                              </div>

                              <div className='d-flex justify-content-center'>
                                    <div style={{width: '500px'}}>
                                          <button onClick={()=>navigate('/manageOffers')} className="btn btn-secondary rounded-pill ">Close</button>
                                    </div>
                              </div>
                        </div>
                  </div>

            </div>
      </>
};
