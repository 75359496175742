const env_development=
{
      backendBaseUrl: 'http://localhost:3001',
      // backendBaseUrl: 'http://192.168.0.165:3001', //for webview
};


const env_production=
{
      backendBaseUrl: 'https://myxacademy.com',
};


const env_common=
{
      facebookPageLink: 'https://www.facebook.com/profile.php?id=61557189632126'
};


let env=(process.env.NODE_ENV==='production') ? env_production : env_development;
env={...env, ...env_common};
export {env};
