//libraries
import axios from "axios";
import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";
import StarRatings from 'react-star-ratings';


//js
import { env } from '../assets/env';
import { formatDateTime } from "../assets/formatDateTime";
import {getAvgRating} from '../assets/getAvgRating';


export function Details({user, profileImgHash, dateNow})
{
      const [searchParams, setSearchParams]=useSearchParams();
      const [detailsUser, setDetailsUser]=useState(false);
      const [rating, setRating]=useState(0);
      const [review, setReview]=useState('');
      const [reviewsArr, setReviewsArr]=useState([]);
      const [wasDealEverDone, setWasDealEverDone]=useState(false);


      useEffect(()=>
      {
            let id_users=searchParams.get('ui');

            (async function()
            {
                  let res=await axios.post('/getUserFromId', {id_users});
                  setDetailsUser(res.data.user);
            })();
      },
      []);


      useEffect(()=>
      {
            if(detailsUser===false) return;

            updateReviewsArr();
      },
      [detailsUser]);


      useEffect(()=>
      {
            if(user===false) return;

            (async function()
            {
                  let res=await axios.post('/getMyReviews', {id_users: detailsUser.id_users});

                  if(res.data.isPrevReviewed===true)
                  {
                        setRating(res.data.rating);
                        setReview(res.data.review);
                  };
            })();

            (async function()
            {
                  let res=await axios.post('/wasDealEverDone', {id_users: detailsUser.id_users});
                  setWasDealEverDone(res.data.status);
            })();
      },
      [user, detailsUser]);


      async function updateReviewsArr()
      {
            let res=await axios.post('/getReviewsArr', {id_users: detailsUser.id_users});
            setReviewsArr(res.data.reviewsArr);
      };


      function postReview()
      {
            (async function()
            {
                  let res=await axios.post('/postReview', {reviewOf: detailsUser.id_users, rating, review});

                  if(res.data.status===false) alert(res.data.msg);

                  if(res.data.status===true)
                  {
                        alert(res.data.msg);

                        updateReviewsArr();
                  };
            })();
      };


      function deleteReview()
      {
            (async function()
            {
                  let res=await axios.post('/deleteReview', {reviewOf: detailsUser.id_users});

                  if(res.data.status===true)
                  {
                        alert('Your review has been deleted');

                        updateReviewsArr();

                        setRating(0);
                        setReview('');
                  };
            })();
      };


      return<>
            <h2 className='text-center mt-4 mb-4'>Details</h2>

            <div className="d-flex justify-content-center">
                  <div className="table" style={{maxWidth: '600px', marginBottom: '80px'}}>

                        <div className="bg-white rounded py-3 px-sm-3 px-2 mb-5">
                              <div className="d-flex align-items-center mb-3">
                                    <img className='rounded-circle mr-2' src={`${env.backendBaseUrl}/profileImages/${detailsUser.id_users}.jpg`+(detailsUser.id_users===user.id_users ? `?${profileImgHash}` : `?${dateNow}`)} onError={(e)=>e.target.src=require('../assets/defaultImage.jpg')} style={{height: '60px', width: '60px', objectFit: 'cover'}} />
                                    <div style={{fontSize: '1.5rem'}}>{detailsUser.username}</div>
                              </div>
                              <div>{detailsUser.profile_description}</div>
                        </div>


                        { (wasDealEverDone) &&
                              <div id="ratingBox" className="bg-white rounded pt-3 pb-2 px-sm-3 px-2 mb-5">
                                    <div className="font-weight-bold mb-3" style={{fontSize: 'large'}}>Post an honest review:</div>

                                    <div className="d-flex align-items-center mb-3">
                                          <div className="mr-2">Rating:</div>
                                          <StarRatings rating={rating} changeRating={setRating} starRatedColor='#FFC107' starHoverColor='#FFC107' starDimension={ (window.innerWidth>600) ? `${45}px` : `${window.innerWidth/10}px` } starSpacing='1px' />
                                          <div className="ml-2" style={{fontSize: (window.innerWidth>600) ? `${30}px` : `${window.innerWidth/15}px` }}>{rating}</div>
                                    </div>

                                    <textarea value={review} onChange={e=>setReview(e.target.value)} className="form-control mb-2" placeholder="Write a review..."></textarea>

                                    <div className="d-flex justify-content-between align-items-center">
                                          <div className="font-weight-bold text-danger c-pointer" onClick={deleteReview}>Delete</div>
                                          <div className="btn btn-primary rounded-pill px-4" onClick={postReview}>Post</div>
                                    </div>
                              </div>
                        }


                        <div className="bg-white rounded-top" style={{paddingTop: '25px', paddingBottom: '25px'}}>
                              <div className="ml-3" style={{fontSize: 'larger'}}>
                                    <div className="d-flex align-items-center mb-2">
                                          <div className="mr-2">Average Rating:</div>
                                          <div className="font-weight-bold mr-2" style={{fontSize: 'larger'}}>{getAvgRating(reviewsArr)}</div>
                                          <div className="text-muted">({reviewsArr.length})</div>
                                    </div>
                              </div>
                        </div>


                        { (reviewsArr.length!==0) &&
                              <div className="bg-white rounded-bottom pt-4 pb-1 px-2" style={{borderTop: '2px solid rgb(222, 222, 222)'}}>
                                    {reviewsArr.toReversed().map((rev, i)=><>
                                          <div className="mb-3" key={rev.id_reviews}>
                                                { (i!==0) && <hr /> }
                                                <div className="d-flex align-items-start">
                                                      <img className='rounded-circle mr-2' src={`${env.backendBaseUrl}/profileImages/${rev.id_users}.jpg`+(rev.id_users===user.id_users ? `?${profileImgHash}` : `?${dateNow}`)} onError={(e)=>e.target.src=require('../assets/defaultImage.jpg')} style={{height: '40px', width: '40px', objectFit: 'cover'}} />
                                                      <div className="flex-fill d-flex justify-content-between">
                                                            <div className="font-weight-bold mr-2">{rev.username}</div>
                                                            <div className="font-weight-bold text-warning"><i className="fa-solid fa-star" /> {rev.rating}</div>
                                                      </div>
                                                </div>

                                                <div className="ml-5" style={{marginTop: '-10px'}}>
                                                      <div className="text-muted" style={{fontSize: 'small'}}>{formatDateTime(rev.reviewedOn)}</div>
                                                      <div className="mt-2">{rev.review}</div>
                                                </div>
                                                </div>
                                    </>)}

                              </div>
                        }

                  </div>
            </div>
      </>
};
