//libraries
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import TextareaAutosize from 'react-textarea-autosize';
import axios from "axios";
import Ripples from "react-ripples";

//js
import {formatDateTime} from '../assets/formatDateTime.js';
import { env } from "../assets/env.js";
import { isMobileOrTablet } from "../assets/isMobileOrTablet.js";

//css
import '../styles/Inbox.css';


export  function Inbox({user, setUser, socket, chatBoxes, setChatBoxes, renderNavbar, newSocketMsg, profileImgHash, renderActiveStatus, dateNow, sentDeal, setSentDeal, receivedDealsArr, setReceivedDealsArr, setAcceptedReceivedDeal, newSocketMsg_selfInitiated})
{
      const [searchParams, setSearchParams]=useSearchParams();
      const [messageInput, setMessageInput]=useState('');
      const [newMsgAlert, setNewMsgAlert]=useState('');
      const [isChatsLoadComplete, setIsChatsLoadComplete]=useState(false);
      const [inboxUser, setInboxUser]=useState(false);
      const [chats, setChats]=useState(false);
      const [currChats, setCurrChats]=useState(false);
      const [willRun, setWillRun]=useState(false);
      const [typingAlert, setTypingAlert]=useState('');
      const [isBtnsHidden, setIsBtnsHidden]=useState(false);
      const [textareaHeight, setTextareaHeight]=useState(false);
      const [imageFile, setImageFile]=useState(false);
      const [uploadProgress, setUploadProgress]=useState(0);
      const [currReceivedDeal, setCurrReceivedDeal]=useState(false);
      const currChatsPrevLength=useRef(false);
      const navigate=useNavigate();


      useEffect(()=>
      {
            if(chatBoxes===false) return;

            //setting inboxUser state by retrieving from chatBoxes props
            for(let i=0; i<=chatBoxes.length-1; i++)
            {
                  let chatBox=chatBoxes[i];

                  if( chatBox.inboxUser.id_users===Number( searchParams.get('ui') ) )
                  {
                        setInboxUser(chatBox.inboxUser);

                        setChats(chatBox.chats);

                        if(currChats===false)
                        {
                              //changeHere initial number of chat messages to be loaded
                              let initialLoadCount=50;
                              if(initialLoadCount>chatBox.chats.length) initialLoadCount=chatBox.chats.length;
                              setCurrChats( chatBox.chats.slice(chatBox.chats.length-initialLoadCount, chatBox.chats.length) );
                        };

                        break;
                  };
            };
      },
      [chatBoxes]);


      useEffect(()=>
      {
            if(newSocketMsg===false) return;

            let data=newSocketMsg;

            //if the message is not sent by this user, then don't do anything
            if(data.fromId!==inboxUser.id_users) return;

            let newChatObject=
            {
                  fromId: data.fromId,
                  toId: data.toId,
                  body: data.body,
                  dati: new Date(),
                  isSeen: 0
            };

            // ---------- updating currChats ----------
            let currChatsClone=JSON.parse(JSON.stringify(currChats));
            currChatsClone.push(newChatObject);
            setCurrChats(currChatsClone);


            setTypingAlert('');
      },
      [newSocketMsg]);


      useEffect(()=>
      {
            if(user===false || currChats===false) return;

            const c=document.getElementById('chatBoxMiddle');

            //this will run on initial render
            if(isChatsLoadComplete===false)
            {
                  //Scroll to bottom. Add smooth scrolling effect.
                  c.scrollTop=c.scrollHeight;
                  c.style.scrollBehavior='smooth';

                  makeMsgSeen();

                  //updating isChatsLoadComplete value to true
                  setIsChatsLoadComplete(true);
            };

            //if a new message is sent or received then this block will run
            //otherwise if more messages are loaded then then this will not run
            if(currChatsPrevLength.current+1===currChats.length)
            {
                  //either scroll to bottom (if sender is this user) or show newMsgAlert message (if sender is another user) everytime after initial render
                  if( (c.scrollHeight-c.scrollTop < c.offsetHeight+180) || (currChats.length!==0 && currChats[currChats.length-1].fromId===user.id_users) ) c.scrollTop=c.scrollHeight;
                  else setNewMsgAlert('New message <i className="fa-solid fa-down"></i>');
            };
      },
      [user, currChats]);


      useEffect(()=>
      {
            currChatsPrevLength.current=currChats.length;
      },
      [currChats]);


      useEffect(()=>
      {
            if(currChats===false) return;

            const c=document.getElementById('chatBoxMiddle');

            //if chats are not scrollable yet
            if(c.scrollHeight - c.offsetHeight===0)
            {
                  makeMsgSeen();
            };
      },
      [typeof(currChats), chatBoxes]);


      useEffect(()=>
      {
            if(messageInput.replace(/\s/g, '').length) setWillRun(true);

            if(messageInput==='') setIsBtnsHidden(false);
            else setIsBtnsHidden(true);
      },
      [messageInput]);


      useEffect(()=>
      {
            if(willRun===false || socket===false) return;

            socket.emit('sendTypingIndicator',
            {
                  id_users: inboxUser.id_users,
            });

            setTimeout(()=>
            {
                  setWillRun(false);
            },
            5000);
      },
      [willRun, socket]);


      useEffect(()=>
	{
		if(socket===false || inboxUser===false) return;


		socket.on('receiveTypingIndicator_Inbox', (data)=>
		{
			if(data.id_users===inboxUser.id_users)
                  {
                        setTypingAlert('Typing...');

                        setTimeout(()=>
                        {
                              setTypingAlert('');
                        },
                        5000);
                  };
		});

            return ()=>
            {
                  socket.off('receiveTypingIndicator_Inbox');
            };
	},
	[socket, inboxUser]);


      useEffect(()=>
      {
            if(imageFile===false) return;

            let confirmImageUpload=window.confirm(`Do you want to send this image? Filename: ${imageFile.name}`);
            if(confirmImageUpload===false) return setImageFile(false);

            const formData=new FormData();
            formData.append('file', imageFile);

            (async function()
            {
                  let res=await axios.post(`/uploadChatImage?toId=${inboxUser.id_users}`, formData,
                  {
                        onUploadProgress: (progressEvent)=> setUploadProgress(Math.floor(progressEvent.progress*100)),
                  });

                  if(res.data.isUploaded===false) return alert(res.data.errorMsg);

                  if(res.data.isUploaded===true) return sendChatMessage('/chatImages/'+res.data.filename);
            })();

            setImageFile(false);
      },
      [imageFile]);


      useEffect(()=>
      {
            if(inboxUser===false) return;


            for(let i=0; i<=receivedDealsArr.length-1; i++)
            {
                  let receivedDeal=receivedDealsArr[i];

                  if(receivedDeal.fromId===inboxUser.id_users && receivedDeal.cancelledBy===0 && receivedDeal.isAccepted===0)
                  {
                        return setCurrReceivedDeal(receivedDeal);
                  };
            };

            return setCurrReceivedDeal(false);
      },
      [receivedDealsArr, inboxUser]);


      useEffect(()=>
	{
		if(newSocketMsg_selfInitiated===false || currChats===false) return;

		let data=newSocketMsg_selfInitiated;

		let newChatObject=
		{
			fromId: data.fromId,
			toId: data.toId,
			body: data.body,
			dati: new Date(),
			isSeen: 0
		};

		// ---------- updating currChats ----------
            let currChatsClone=JSON.parse(JSON.stringify(currChats));
            currChatsClone.push(newChatObject);
            setCurrChats(currChatsClone);
	},
	[newSocketMsg_selfInitiated]);


      function sendChatMessage(messageInputClone=messageInput)
      {
            if(messageInput.trim().slice(0, 12)==='/chatImages/') return alert('This attampt may cause security risk for your account');

            if(user===false || messageInputClone.replace(/\s/g, '')==='' || isNaN(inboxUser.id_users) || inboxUser.id_users===user.id_users || socket.connected===false) return;


            // ------------------ sending chat message ------------------


            setMessageInput('');

            socket.emit('sendMessage',
            {
                  toId: inboxUser.id_users,
                  body: messageInputClone
            }
            ,
            function(cbData)
            {
                  if(cbData.status===false) return alert(cbData.msg);


                  let newChatObject=
                  {
                        fromId: user.id_users,
                        toId: inboxUser.id_users,
                        body: messageInputClone.trim(),
                        dati: new Date(),
                        isSeen: 0
                  };

                  // ---------- updating chatBoxes ----------
                  let chatBoxesClone=JSON.parse(JSON.stringify(chatBoxes));
                  for(let i=0; i<=chatBoxesClone.length-1; i++)
                  {
                        let chatBox=chatBoxesClone[i];
                        if(chatBox.inboxUser.id_users===inboxUser.id_users)
                        {
                              chatBox.chats.push(newChatObject);
                              break;
                        };
                  };
                  setChatBoxes(chatBoxesClone);

                  // ---------- updating currChats ----------
                  let currChatsClone=JSON.parse(JSON.stringify(currChats));
                  currChatsClone.push(newChatObject);
                  setCurrChats(currChatsClone);
            });
      };


      function loadMoreChats()
      {
            //changeHere number of loaded chat messages to increment
            let loadCountIncrement=50;

            let newLoadCount=currChats.length+loadCountIncrement;

            if(newLoadCount>chats.length) newLoadCount=chats.length;

            setCurrChats( chats.slice(chats.length-newLoadCount, chats.length) );
      };


      function chatsOnScroll()
      {
            const c=document.getElementById('chatBoxMiddle');

            //if user scrolls near bottom
            if(c.scrollHeight - c.scrollTop - c.offsetHeight < 100)
            {
                  //if newMsgAlert exists, then removing it
                  if(newMsgAlert!=='') setNewMsgAlert('');

                  makeMsgSeen();
            };
      };


      function getUsername(chat)
      {
            if(chat.fromId===inboxUser.id_users) return inboxUser.username;
            if(chat.fromId===user.id_users) return 'Me';

            return '[Other]';
      };


      function makeMsgSeen()
      {
            let lastCurrChat=currChats[currChats.length-1];

            if(lastCurrChat.toId!==user.id_users || lastCurrChat.isSeen===1) return;


            //on frontend
            lastCurrChat.isSeen=1;

            let chatBoxesClone=JSON.parse(JSON.stringify(chatBoxes));
            for(let i=0; i<=chatBoxesClone.length-1; i++)
            {
                  let chatBox=chatBoxesClone[i];
                  if(chatBox.inboxUser.id_users===lastCurrChat.fromId)
                  {
                        chatBox.chats[chatBox.chats.length-1].isSeen=1;
                        break;
                  };
            };
            setChatBoxes(chatBoxesClone);


            //on backend i.e. database
            socket.emit('makeMsgSeen',
            {
                  id_users: inboxUser.id_users
            });
      };


      function sendDealBtn()
      {
            let payment=prompt('Enter payment you want to receive for this help');
            if(payment===null) return;

            socket.emit('send_deal',
            {
                  toId: inboxUser.id_users,
                  payment: payment
            }
            ,
            function(cbData)
            {
                  if(cbData.status===false) alert(cbData.msg);

                  if(cbData.status===true) setSentDeal(`You requested to help ${inboxUser.username} for ${payment} coins`);
            });
      };


      function cancelReceivedDealBtn()
      {
            let fromId=inboxUser.id_users;
            let toId=user.id_users;


            socket.emit('send_cancel_receivedDeal',
            {
                  fromId: fromId
            }
            ,
            function(cbData)
            {
                  if(cbData.status===false) return alert(cbData.msg);

                  if(cbData.status===true)
                  {
                        let receivedDealsArrClone=JSON.parse(JSON.stringify(receivedDealsArr));

                        for(let i=0; i<=receivedDealsArrClone.length-1; i++)
                        {
                              let receivedDeal=receivedDealsArrClone[i];

                              if(receivedDeal.fromId===fromId && receivedDeal.cancelledBy===0 && receivedDeal.isAccepted===0)
                              {
                                    receivedDeal.cancelledBy=toId;
                                    break;
                              };
                        };

                        setReceivedDealsArr(receivedDealsArrClone);
                  };
            });
      };


      function acceptDealBtn()
      {
            let fromId=inboxUser.id_users;

            socket.emit('send_accept_deal',
            {
                  fromId: fromId
            }
            ,
            function(cbData)
            {
                  if(cbData.status===false) return alert(cbData.msg);

                  if(cbData.status===true)
                  {
                        let receivedDealsArrClone=JSON.parse(JSON.stringify(receivedDealsArr));

                        for(let i=0; i<=receivedDealsArrClone.length-1; i++)
                        {
                              let receivedDeal=receivedDealsArrClone[i];

                              if(receivedDeal.fromId===fromId && receivedDeal.cancelledBy===0 && receivedDeal.isAccepted===0)
                              {
                                    receivedDeal.isAccepted=1;
                                    break;
                              };
                        };

                        setReceivedDealsArr(receivedDealsArrClone);


                        // ----- showing accepted received deal -----
                        let username=cbData.username;
                        let payment=cbData.payment;

                        setAcceptedReceivedDeal(`${username} is helping you for ${payment} coins`);


                        //decreasing coins
                        let userClone=JSON.parse(JSON.stringify(user));
                        userClone.coins_learner=userClone.coins_learner-payment;
                        setUser(userClone);
                  };
            });
      };


      function formatChatBody(chat)
      {
            if(chat.shouldHide) return;

            let body=chat.body;

            let dealText=getDealText(user.id_users, inboxUser.id_users, inboxUser.username, body);

            if(dealText!==false)
            {
                  return <div className="px-2 py-1 rounded font-weight-bold text-secondary" style={{backgroundColor: 'whitesmoke'}}>{dealText}</div>;
            }

            else if(body.slice(0, 12)==='/chatImages/')
            {
                  return <img className="img-fluid rounded-lg" src={env.backendBaseUrl+body} style={{maxHeight: '18rem', maxWidth: '75%'}} />;
            }

            else
            {
                  return body;
            };
      };


      function getDealText(selfId, theirId, theirUsername, body)
      {
            let dealInfoArr=body.split('_');

            let dealType=dealInfoArr[1];
            let dealFromId=Number(dealInfoArr[2]);
            let dealToId=Number(dealInfoArr[3]);
            let dealPayment=Number(dealInfoArr[4]);

            if( ! dealFromId || ! Number.isInteger(dealFromId) ) return false;
            if( ! dealToId || ! Number.isInteger(dealToId) ) return false;
            if( ! dealPayment || ! Number.isInteger(dealPayment) ) return false;

            theirUsername=theirUsername.split(' ')[0];


            if(dealType==='dealRequest')
            {
                  if(dealFromId===selfId && dealToId===theirId)
                  {
                        return `You requested to help ${theirUsername} for ${dealPayment} coins`;
                  };

                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} wants to help you for ${dealPayment} coins`;
                  };
            };


            if(dealType==='cancelReceivedDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId)
                  {
                        return `You cancelled ${theirUsername}'\s deal of ${dealPayment} coins`;
                  };

                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} cancelled your deal of ${dealPayment} coins`;
                  };
            };


            if(dealType==='cancelSentDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId)
                  {
                        return `You cancelled your deal of ${dealPayment} coins`;
                  };

                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} cancelled their deal of ${dealPayment} coins`;
                  };
            };


            if(dealType==='acceptDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId)
                  {
                        return `You accepted ${theirUsername}\'s deal of ${dealPayment} coins`;
                  };

                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} accepted your deal of ${dealPayment} coins`;
                  };
            };


            if(dealType==='finishReceivedDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId)
                  {
                        return `You finished ${theirUsername}\'s deal of ${dealPayment} coins. Please give a review (click on their name at the top).`;
                  };

                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} finished your deal of ${dealPayment} coins`;
                  };
            };


            if(dealType==='finishSentDeal')
            {
                  if(dealFromId===selfId && dealToId===theirId)
                  {
                        return `You finshed your deal of ${dealPayment} coins`;
                  };

                  if(dealFromId===theirId && dealToId===selfId)
                  {
                        return `${theirUsername} finished their deal of ${dealPayment} coins. Please give a review (click on their name at the top).`;
                  };
            };


            return false;
      };


      return<>
            <div className="d-flex flex-column" style={{height: '100%', width: '100%', position: 'absolute'}}>

                  <div className="sticky-top">
                        {renderNavbar('messages')}

                        {/* top */}
                        <div className="d-flex justify-content-center">
                              <div className="d-flex border-bottom bg-white justify-content-between" onClick={()=>navigate(`/details?ui=${inboxUser.id_users}`)} style={{width: '600px'}}>
                                    <div className="d-flex">
                                          <img className="rounded-circle mx-1 my-1" src={`${env.backendBaseUrl}/profileImages/${inboxUser.id_users}.jpg?${dateNow}`} onError={e=>e.target.src=require('../assets/defaultImage.jpg')} style={{height: '40px', width: '40px', objectFit: 'cover'}} />

                                          <div className="d-flex flex-column justify-content-center">
                                                <div className="d-flex">
                                                      <div className="mr-1">{inboxUser.username!==undefined && inboxUser.username.slice(0, 20)}</div>
                                                      <div>{renderActiveStatus(inboxUser.id_users)}</div>
                                                </div>
                                          </div>
                                    </div>
                                    <b className="d-flex align-items-center mr-3">
                                          <i class="fa-solid fa-bars lightAsh"></i>
                                    </b>
                              </div>
                        </div>

                        { (currReceivedDeal!==false) &&
                              <div className="d-flex justify-content-center">
                                    <div className="bg-primary text-light pt-1 pb-2 px-2 font-weight-bold rounded" style={{width: '600px'}}>
                                          <div className="mb-2">{inboxUser.username} wants to help you for {currReceivedDeal.payment} coins</div>
                                          <div className="d-flex justify-content-around">
                                                <div className="btn btn-success rounded-pill py-1 px-3" onClick={acceptDealBtn}>Accept</div>
                                                <div className="btn btn-danger rounded-pill py-1 px-3" onClick={cancelReceivedDealBtn}>Cancel</div>
                                          </div>
                                    </div>
                              </div>
                        }
                  </div>

                  <div className="flex-fill d-flex justify-content-center">
                        <div className="d-flex flex-column bg-white" style={{width: window.innerWidth, maxWidth: '600px'}}>

                              {/* middle */}
                              <div id="chatBoxMiddle" className="flex-fill" style={{height: '0', overflowY: 'auto'}} onScroll={chatsOnScroll}>
                                    <div className={ (isChatsLoadComplete===false) ? ' invisible' : ''}>

                                          {/* 'load more' button */}
                                          { (currChats.length<chats.length) && <div className="text-center bg-primary text-white py-1" onClick={loadMoreChats}>Load more</div>}

                                          {/* chats start */}
                                          { (currChats!==false) && currChats.map(function(chat, i){
                                                return(
                                                      <div hidden={chat.shouldHide} className="px-1" style={{paddingTop: '12.5px', paddingBottom: '12.5px'}} key={i}>

                                                            <div className="d-flex">

                                                                  <img className="rounded-circle mr-2" src={`${env.backendBaseUrl}/profileImages/${chat.fromId}.jpg` + (chat.fromId===user.id_users ? `?${profileImgHash}` : `?${dateNow}`) } onError={e=>e.target.src=require('../assets/defaultImage.jpg')} style={{height: '40px', width: '40px', objectFit: 'cover'}} />

                                                                  <div className="d-flex justify-content-between w-100">
                                                                        <b className="mr-2 text-truncate">{chat!==undefined && getUsername(chat).slice(0, 20)}</b>
                                                                        <span className="text-muted text-nowrap" style={{fontSize: 'small'}}>{formatDateTime(chat.dati)}</span>
                                                                  </div>

                                                            </div>

                                                            <div className="text-break" style={{marginLeft: '49px', whiteSpace: 'pre-wrap', marginTop: '-10px'}}>
                                                                  { formatChatBody(chat) }
                                                            </div>

                                                      </div>
                                                );
                                          })}

                                    </div>
                              </div>

                              {/* file uploading alert */}
                              <div className="text-center" hidden={uploadProgress===0 || uploadProgress===100}>
                                    <b>Uploading file: {uploadProgress}% complete</b>
                              </div>

                              {/* typingAlert */}
                              <div className="text-center">
                                    <b dangerouslySetInnerHTML={{__html: typingAlert}}></b>
                              </div>

                              {/* newMsgAlert */}
                              <div className="text-center">
                                    <b dangerouslySetInnerHTML={{__html: newMsgAlert}}></b>
                              </div>

                              {/* bottom */}
                              <div>
                                    <div className="input-group pt-2 pb-3 px-1">

                                          { (! isBtnsHidden) &&
                                                <div className="d-flex align-items-center">

                                                      { (user.teacherMode==='on') &&
                                                            <Ripples className="rounded-pill">
                                                                  <div className="btn" onClick={sendDealBtn} style={{color: '#0099FF'}}>
                                                                        <i className="fa-duotone fa-handshake fa-lg"></i>
                                                                  </div>
                                                            </Ripples>
                                                      }

                                                      <Ripples className="rounded-pill">
                                                            <div className="btn" onClick={()=>document.getElementById('inputImageId').click()} style={{color: '#0099FF'}}>
                                                                  <i className="fa-regular fa-image fa-lg"></i>
                                                            </div>
                                                      </Ripples>
                                                      <input hidden id="inputImageId" onChange={(e)=>setImageFile(e.target.files[0])} type='file' />

                                                </div>
                                          }

                                          <TextareaAutosize maxRows={6} onHeightChange={(rowHeight)=>setTextareaHeight(rowHeight)} autoFocus={! isMobileOrTablet()} autoComplete="off" onKeyDown={ (e)=> { if(e.key==='Enter' && e.shiftKey===false && !isMobileOrTablet() ) {e.preventDefault(); sendChatMessage();} } } className={"form-control border-0"+(textareaHeight>36 ? ' rounded-lg' : ' rounded-pill')} onChange={(e)=>setMessageInput(e.target.value)} value={messageInput} placeholder={ (isBtnsHidden===false) ? 'Message' : 'Type a message...'} type="text" style={{backgroundColor: 'whitesmoke', boxShadow: 'none', resize: 'none'}} onFocus={()=>setIsBtnsHidden(true)} onBlur={()=>setIsBtnsHidden(false)} />

                                          <Ripples className="d-flex align-items-center rounded-pill">
                                                <div className="btn" onClick={()=>sendChatMessage()} style={{color: '#0099FF'}}>
                                                      <i className="fa-solid fa-paper-plane-top fa-lg"></i>
                                                </div>
                                          </Ripples>

                                    </div>
                              </div>

                        </div>
                  </div>

            </div>
      </>
};
